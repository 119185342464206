import { useState, useEffect, useRef, Dispatch } from 'react';
import classNames from 'classnames';
import Checkbox from '../checkbox';
import { ArrowBlack, CloseIcon, WhiteClose } from 'src/assets/icons';
import classes from './styles.module.scss';

interface ISelectProps<T> {
  options?: T[];
  selectedOptions?: T[];
  valueKey?: keyof T;
  labelKey?: keyof T;
  label?: string;
  secondLabel?: string;
  handleSelect: (selected: T[]) => void;
  isMultiple?: boolean;
  maxLength?: number;
  handleChangeList?: (type: string) => void;
  listType?: string;
  placeholder?: string;
  sources?: boolean;
  contentSize?: number;
  right?: boolean;
}

const Select = <T,>({
  options,
  label,
  secondLabel,
  handleSelect,
  selectedOptions = [],
  maxLength = 1,
  isMultiple = false,
  handleChangeList,
  listType,
  placeholder = 'Выберите',
  sources,
  contentSize = 3,
  right = false,
  valueKey = 'id' as keyof T,
  labelKey = 'name' as keyof T,
}: ISelectProps<T>) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelectOption = (option: T) => {
    if (isMultiple) {
      handleSelect(
        selectedOptions.some((item) => item[valueKey] === option[valueKey])
          ? selectedOptions.filter((item) => item[valueKey] !== option[valueKey])
          : [...selectedOptions, option],
      );
    } else {
      handleSelect([option]);
    }
  };

  const handleDeselectOption = (option?: T) => {
    if (option) {
      const filtered = selectedOptions.filter((item) => item[valueKey] !== option[valueKey]);
      handleSelect(filtered);
    } else {
      handleSelect([]);
    }
  };

  const handleSelectAll = () => {
    if (options) {
      const allSelected = options.every((option) =>
        selectedOptions.some((item) => item[valueKey] === option[valueKey]),
      );
      handleSelect(allSelected ? [] : options);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node) &&
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    const existIds = options?.map((item) => item[valueKey]) || [];
    handleSelect(selectedOptions.filter((item) => existIds.includes(item[valueKey])));
  }, [options]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames(classes['select'])}>
      {label && (
        <div className={classes['select__label']}>
          <span
            onClick={() => {
              handleChangeList && handleChangeList('company');
            }}
            className={`${listType === 'company' && classes['active-label']}`}
          >
            {label && label}
          </span>
          {secondLabel && '  /  '}
          <span
            onClick={() => {
              handleChangeList && handleChangeList('division');
            }}
            className={`${listType === 'division' && classes['active-label']}`}
          >
            {secondLabel && secondLabel}
          </span>
        </div>
      )}
      <div
        className={classes['select__wrapper']}
        onClick={() => setShowOptions((prev) => !prev)}
        ref={selectRef}
      >
        <div
          className={classes['select__grid']}
          style={{
            gridTemplateColumns: `repeat(${contentSize}, 1fr)`,
          }}
        >
          {selectedOptions.length > 0 && !sources ? (
            selectedOptions.map((item) => (
              <div
                key={item[valueKey] as React.Key}
                className={classes['select__selected']}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <p>{item[labelKey] as React.ReactNode}</p>
                {isMultiple && (
                  <img src={CloseIcon} alt="close" onClick={() => handleDeselectOption(item)} />
                )}
              </div>
            ))
          ) : sources && selectedOptions.length > 0 ? (
            <div className={classes['select__selected']}>
              <p>{selectedOptions.length}</p>
              {isMultiple && (
                <img src={CloseIcon} alt="close" onClick={() => handleDeselectOption()} />
              )}
            </div>
          ) : (
            <span className={classes['placeholder']}>{placeholder}</span>
          )}
        </div>
        <img src={ArrowBlack} alt="ArrowBlack" />
      </div>
      {showOptions && (
        <div
          className={classes['select__options']}
          ref={optionsRef}
          style={{
            left: right ? 'auto' : 0,
            right: right ? 0 : 'auto',
          }}
        >
          <li style={{ userSelect: 'none' }} className={classNames(classes['option'])}>
            <Checkbox
              id="select-all"
              label="Выбрать все"
              checked={options?.every((option) =>
                selectedOptions.some((item) => item[valueKey] === option[valueKey]),
              )}
              onChange={handleSelectAll}
            />
          </li>
          {options &&
            options.map((option) => (
              <li
                style={{
                  userSelect: 'none',
                }}
                className={classNames(
                  classes[
                    `option--${!!selectedOptions.find(
                      (item) => item[valueKey] === option[valueKey],
                    )}`
                  ],
                )}
                key={option[valueKey] as React.Key}
              >
                <Checkbox
                  id={option[valueKey] as string}
                  label={option[labelKey] as string}
                  checked={!!selectedOptions.find((item) => item[valueKey] === option[valueKey])}
                  onChange={() => handleSelectOption(option)}
                  disabled={
                    !selectedOptions.find((item) => item[valueKey] === option[valueKey]) &&
                    selectedOptions.length >= maxLength
                  }
                />
              </li>
            ))}
        </div>
      )}
    </div>
  );
};

export default Select;
