import React from 'react';
import { findColorById } from 'src/utils/helpers';
import { DiagramCompanyType, DiagramDivisionType, DiagramEmissionType } from 'src/consts/types';
import classes from './styles.module.scss';

const DiagramTable: React.FC<{
  list: DiagramCompanyType[] | DiagramDivisionType[] | DiagramEmissionType[];
  itemNameWidth?: string;
  hideLine?: boolean;
}> = ({ list, itemNameWidth, hideLine }) => {
  return list.length > 0 ? (
    <table className={classes['diagramTable']}>
      <thead>
        <tr className={classes['table-head-diagram']}>
          <th></th>
          {!hideLine && <th></th>}
          <th>Общий норматив ПДВ (т/г)</th>
          <th>Норматив ПДВ активных источников (т/г)</th>
          <th>Фактические выбросы (т)</th>
          <th>Остаток по норме ПДВ (т)</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item, index) => (
          <tr key={item.id}>
            <td className={classes['diagramTable__row--name']}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    fill={findColorById(item.listType === 'emission' ? index + 1 : item.id)}
                  />
                </svg>
              </span>
              <span style={{ width: itemNameWidth }}>{item.name}</span>
            </td>
            {!hideLine && (
              <td>
                <div className={classes['diagramTable__row--divider']}>
                  ----------------------------
                </div>
              </td>
            )}

            <td>
              <div className={classes['diagramTable__row--values']}>
                {item?.totalNorma.toFixed(2)}т/г
              </div>
            </td>
            <td>
              <div className={classes['diagramTable__row--values']}>
                {item.params.reduce((acc, param) => acc + param.norma, 0).toFixed(2)}т/г
              </div>
            </td>
            <td>
              <div className={classes['diagramTable__row--values']}>
                {item.params.reduce((acc, param) => acc + param.value, 0).toFixed(2)}т
                <span
                  className={
                    classes[
                      `triangle-${
                        item.params.reduce((acc, param) => acc + param.value, 0) >
                        item.params.reduce((acc, param) => acc + param.norma, 0)
                          ? 'up'
                          : 'down'
                      }`
                    ]
                  }
                ></span>
              </div>
            </td>
            <td>
              <div className={classes['diagramTable__row--values']}>
                {(
                  item.totalNorma - item.params.reduce((acc, param) => acc + param.value, 0)
                ).toFixed(2)}
                т/г
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : null;
};

export default DiagramTable;
